import React from 'react';
import styled from 'styled-components';
import Layout from "../components/layout";
import SectionFooter from '../components/sections/section-footer/SectionFooter';
import { theme } from "../utils/theme"
import {Grid} from '@material-ui/core';
import {Link} from 'gatsby';


const Container = styled.div`
  /* height: 100vh; */
  width: 100%;
  background-color: ${props => props.theme.colors.dark};
  overflow-y: visible;
`

const TitleCond = styled.h5`
  font-size: 3rem;
  font-weight: 300;
  width: 300px;
  line-height: 1.1;
  color: ${props => props.theme.colors.body};
  margin-bottom: 100px;
  text-align: left;

  @media ${props => props.theme.breaks.down('md')} {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
`

const StorySection = styled.div`
  min-height: 100vh;
  padding-top: 50px;
  text-align: center;

  @media ${props => props.theme.breaks.down('md')} {
    .title-cond {
      width: 90% !important;
    }
  }
`

const PointContainer = styled.div`
  width: 70%;
  margin: 0 auto;
  margin-top: 7rem;
  position: relative;


  .grid-list {
    margin-bottom: 40px;
  }

  .conclusion {
    margin-top: 50px;
  }

  .links {
    margin-top: 50px;
  }

  h3 {
    color: ${props => props.theme.colors.body};
    text-align: left;

    @media ${props => props.theme.breaks.down('md')} {
      font-size: 2.5rem;
    }
  }

  h4 {
    color: ${props => props.theme.colors.body};
    text-transform: none;
    text-align: left;

    @media ${props => props.theme.breaks.down('md')} {
      color: ${props => props.theme.colors.primary};
      font-size: 1.8rem;
    }
  }

  h5 {
    color: ${props => props.theme.colors.primary};
    text-align: left;

    @media ${props => props.theme.breaks.down('md')} {
      color: ${props => props.theme.colors.body};
      font-size: 2rem;
    }
  }

  p {
    color: ${props => props.theme.colors.primary};
    font-weight: 300;
    text-align: left;
    line-height: 1.2;
  }

  @media ${props => props.theme.breaks.down('md')} {
    width: 90%;
  }
`

const LinkButton = styled(Link)`
  text-decoration: none;
  display: inline-block;
  padding: 15px 5px;
  background-color: ${props => props.theme.colors.body};
  color: ${props => props.theme.colors.dark};
  width: 100%;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.bodyLight};
  }
`


const FixedFooter = styled.div`
  height: 100px;
  width: 100%;
  /* min-width: ${props => props.theme.maxWidthMd};
  max-width: 1400px; */
  margin: 40px auto 0 auto;
  padding-left: 40px;
  position: relative;
  background-color: ${props => props.theme.colors.dark};

  @media ${props => props.theme.breaks.down('md')} {
    padding-left: 0;
  }
`


const LongStoryShortPage = ({ data }) => {


  // const {HeroImage, Heading1, Heading2} = data.strapiHero;
  return (
    
      <Layout color={theme.colors.body} pageTitle="Long story short">
      <Container>
        
        <StorySection className="point-sec">
          <div className="title-cond" style={{width: '70%', margin: '0 auto'}}>
          <TitleCond>LONG STORY SHORT</TitleCond>
          </div>

          <PointContainer>
            <Grid className="grid-list" container alignItems="flex-start" spacing={3}>
              <Grid item lg={4} md={12}>
                <h3>What is branding?</h3>
              </Grid>
              <Grid item lg={8} md={12}>
                <p>It’s the stuff most people think about: the logo, color palette, website, and all the other physical and digital assets that show off your business. It’s also the stuff people think about less: the philosophy and practices that help you stand out and get ahead. It’s how we influence your brand.</p>
              </Grid>
            </Grid>
            <Grid className="grid-list" container alignItems="flex-start" spacing={3}>
              <Grid item lg={4} md={12}>
                <h3>What is a brand?</h3>
              </Grid>
              <Grid item lg={8} md={12}>
                <p>It’s the way people feel about your business at a gut level. Everything you do as a business either reinforces your brand or it doesn’t.</p>
              </Grid>
            </Grid>
            <Grid container className="grid-list" alignItems="flex-start" spacing={3}>
              <Grid item lg={4} md={12}>
                <h3>Why does branding matter?</h3>
              </Grid>
              <Grid item lg={8} md={12}>
                <p>People like to use businesses they believe in. People like to feel like they’re part of a tribe. Branding differentiates you from the competition and makes your business the only option in your customers’ minds. Having a strong brand is good for your customers, for your employees, and for your bottom line. </p>
              </Grid>
            </Grid>

            <Grid container className="conclusion" alignItems="flex-end" spacing={4}>
              <Grid item lg={9} md={12}>
                <h5>Does Celebrand offer incredible branding that gives me everything I need to get ahead in business in a way that’s clear, pleasant, and extremely helpful?</h5>
              </Grid>
              <Grid item lg={3} md={12}>
               <h4>Yes.</h4>
              </Grid>
            </Grid>

            <Grid container className="links" spacing={8}>
              <Grid item xs={12} lg={4} ><LinkButton to="/contact-us">See for Yourself</LinkButton></Grid>
              <Grid item xs={12} lg={4} ><LinkButton to="/portfolio">See What We’ve Done</LinkButton></Grid>
              <Grid item xs={12} lg={4} ><LinkButton to="/services">See What We Can Do</LinkButton></Grid>
            </Grid>
          </PointContainer>
          
        

         <FixedFooter>
          <SectionFooter color={theme.colors.body}></SectionFooter>
          
         </FixedFooter>

         </StorySection>
        
      </Container>
      </Layout>
    
)
}

export default LongStoryShortPage;